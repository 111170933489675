.popup__close-button {
  width: 32px;
  height: 32px;
  position: absolute;
  top: -40px;
  right: -40px;
  background-color: transparent;
  padding: 0;
  border: none;
  background-image: url(../../../images/close-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

@media (max-width: 633px) {
  .popup__close-button {
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
    top: -38px;
    right: 0;
  }
}
