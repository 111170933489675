.auth-form__title {
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  line-height: 29px;
  margin: 0;
}

@media (max-width: 633px) {
  .auth-form__title {
    font-size: 20px;
    line-height: 24px;
  }
}
