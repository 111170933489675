.profile__name {
  margin: 0;
  font-size: 42px;
  line-height: 48px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 380px;
}

@media (max-width: 1199px) {
  .profile__name {
    max-width: 300px;
  }
}

@media (max-width: 1080px) {
  .profile__name {
    font-size: 27px;
    line-height: 32px;
    max-width: 240px;
  }
}
