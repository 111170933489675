.auth-form {
  display: flex;
  flex-direction: column;
  width: 358px;
  margin: 0 auto;
  padding: 60px 0 112px;
}

@media (max-width: 633px) {
  .auth-form {
    width: 260px;
    padding: 40px 0 77px;
  }
}
