.popup__container_show_form {
  width: 430px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 25px rgba(0, 0, 0, .15);
}

@media (max-width: 633px) {
  .popup__container_show_form {
    width: 282px;
  }
}
