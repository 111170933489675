.popup__title_bottom {
  text-align: center;
  padding-top: 34px;
  line-height: 28px;
  margin-bottom: 60px;
}

@media (max-width: 633px) {
  .popup__title_bottom {
    padding-top: 40px;
    margin-bottom: 50px;
    font-size: 20px;
    line-height: 24px;
  }
}
