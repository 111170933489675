.profile__add-button {
  width: 150px;
  height: 50px;
  padding: 0;
  background-color: #000;
  border: solid 2px #fff;
  border-radius: 2px;
  background-image: url(../../../images/add-button.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  grid-area: add-button;
}

@media (max-width: 1080px) {
  .profile__add-button {
    margin: 36px auto 0;
    width: 282px;
  }
}
