.header {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 45px 0 41px;
  line-height: 0;
  border-bottom: solid 1px rgba(84, 84, 84, .7);
}

@media (max-width: 1080px) {
  .header {
    padding: 28px 0 32px;
  }
}
