.hamburger {
  display: none;
  border: none;
  padding: 0;
  position: absolute;
  align-self: flex-end;
  right: 30px;
  bottom: 30px;
  width: 30px;
  height: 24px;
  cursor: pointer;
  background-color: transparent;
}

@media (max-width: 633px) {
  .hamburger {
    display: block;
  }
}
