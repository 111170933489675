.auth-form__input-error {
  color: #FF4500;
  font-size: 12px;
  font-weight: normal;
  display: block;
  min-height: 30px;
  padding-top: 5px;
  opacity: 0;
  transition: .3s;
}
