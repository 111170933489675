.footer {
  padding: 66px 0 60px;
  font-weight: normal;
  font-size: 18px;
  line-height: 18px;
  color: #545454;
}

@media (max-width: 1080px) {
  .footer {
    padding: 48px 19px 36px;
    font-size: 14px;
    line-height: 14px;
  }
}
