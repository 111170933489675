.page {
  box-sizing: border-box;
  margin: 0 auto;
  padding: 0 200px;
  min-width: 320px;
  max-width: 1280px;
  color: #fff;
  font-family: 'Inter', 'Arial', 'Helvetica', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1080px) {
  .page {
    padding: 0;
  }
}
