.popup__field {
  width: 100%;
  padding: 0 0 6px;
  min-height: 27px;
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, .2);
  font-size: 14px;
  line-height: 14px;
  font-weight: normal;
  color: #000;
  box-sizing: border-box;
  position: relative;
  outline: none;
}

.popup__field::placeholder {
  color: #c4c4c4;
}
