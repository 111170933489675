.profile__info {
  display: flex;
  grid-area: info;
  align-self: end;
}

@media (max-width: 1080px) {
  .profile__info {
    margin: 26px 0 0;
    justify-content: center;
  }
}
