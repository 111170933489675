.card__like-btn {
  cursor: pointer;
  width: 22px;
  height: 19px;
  padding: 0;
  border: none;
  background-color: transparent;
  background-image: url(../../../images/heart_disabled.svg);
  background-repeat: no-repeat;
  background-position: center;
  transition: opacity 0.25s;
}

.card__like-btn:hover {
  opacity: .5;
}
