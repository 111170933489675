.hamburger__line_active {
  margin-bottom: -1px;
}

.hamburger__line_active:nth-child(1) {
  transform: translateY(1px) rotate(-45deg);
}

.hamburger__line_active:nth-child(2) {
  display: none;
}

.hamburger__line_active:nth-child(3) {
  transform: translateY(-1px) rotate(45deg);
}
