.profile-menu__link {
  color: #a9a9a9;
  text-decoration: none;
  font-size: 18px;
  line-height: 22px;
}

@media (max-width: 1080px) {
  .profile-menu__link {
    margin-right: 30px;
  }
}

@media (max-width: 633px) {
  .profile-menu__link {
    margin: 18px 0 40px;
  }
}
