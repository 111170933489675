.card__title {
  margin: 0;
  color: #000;
  font-size: 24px;
  line-height: 26px;
  margin-top: -2px;
  font-weight: 900;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: 215px;
}
