.auth-form__btn {
  cursor: pointer;
  height: 50px;
  margin-top: 176px;
  border: none;
  border-radius: 2px;
  outline: none;
  color: #000;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  background-color: #fff;
  transition: opacity 0.25s;
}

.auth-form__btn:hover {
  opacity: 0.85;
}

.auth-form__btn_demo {
  margin-top: 10px;
  color: #fff;
  font-size: 14px;
  height: auto;
  background-color: transparent;
}

.auth-form__btn_demo:hover {
  opacity: 0.6;
}

@media (max-width: 633px) {
  .auth-form__btn {
    height: 46px;
  }
}
