.profile__avatar {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 1080px) {
  .profile__avatar {
    margin: 0 auto;
  }
}
