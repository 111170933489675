.profile__avatar-button {
  cursor: pointer;
  width: 102%;
  height: 102%;
  padding: 0;
  background-color: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 50%;
  background-image: url(../../../images/edit-button.svg);
  background-size: 26px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  opacity: 0;
  transition: opacity .25s;
}

.profile__avatar-button:hover {
  opacity: 1;
}
